@import 'styles/colors';
@import 'styles/variables';

.topbar {
  position: fixed;
  width: calc(100% - 66px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 4.375rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
  background-color: $color-white;
  z-index: 1;

  &__box {
    display: flex;
    align-items: center;
    position: fixed;
    right: 2.5rem;
  }

  &--home-page {
    width: 100%;
    padding: 0 $spacer-2xl;
  }

  &__buttons {
    button {
      &:first-child {
        margin-right: $spacer-lg;
      }
    }
  }

  &__full-name {
    position: relative;
    font-size: $font-size-sm;
    user-select: none;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 28px;
      background-color: #ebebf2;
      top: 50%;
      left: -$spacer-base;
      transform: translateY(-50%);
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    width: 0.875rem;
    margin-left: 0.375rem;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    &--rotated {
      transform: rotate(180deg);
    }

    .svg {
      width: 0.9rem;
    }
  }

  &__initials {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    width: 2.375rem;
    height: 2.375rem;
    border-radius: 50%;
    background-color: $color-neutral-300;
    color: $color-white;
    font-weight: $font-weight-bold;
    margin-left: 0.875rem;
    cursor: pointer;
  }

  &__dropdown {
    position: fixed;
    width: 11.125rem;
    // height: 2.375rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: $spacer-sm;
    background-color: $color-white;
    padding: 0.7rem $spacer-base;
    top: 3.75rem;
    right: 2.5rem;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);

    &-item {
      font-size: $font-size-sm;
      width: 100%;
      cursor: pointer;
      font-weight: $font-weight-regular;
      &:not(:last-child) {
        padding-bottom: $spacer-base;
      }

      &--red {
        font-weight: $font-weight-medium;
        color: $color-red;
      }
    }
  }
}
