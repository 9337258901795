@import 'styles/colors';
@import 'styles/variables';

.button {
  border-radius: $border-radius-sm;
  color: $color-white;
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  border: 1px solid transparent;
  outline: none;

  &:hover {
    cursor: pointer;
  }
}

.primary {
  background-color: $color-primary;
}

.transparent {
  background-color: transparent;
  border-color: $color-white;
  color: $color-neutral-500;
  // font-weight: $font-weight-regular;
}

.black {
  background-color: #17181d;
  border-radius: 8px;
}

.bordered {
  background-color: transparent;
  border-color: $color-primary;
  color: $color-primary;
  // font-weight: $font-weight-regular;
}

.delete {
  background-color: $color-red;
}

.disabled {
  opacity: 0.5;

  &:hover {
    cursor: not-allowed;
  }
}

.small {
  height: 2.5rem;
  padding: 0 1.875rem;
  font-size: $font-size-base;
  // font-weight: $font-weight-regular;
}

.medium {
  height: 50px;
  width: 250px;
}

.fullWidth {
  width: 100%;
}
