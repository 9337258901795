.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: #fff;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  row-gap: 35px;

  a {
    text-decoration: none;
    color: #17181d;
    font-size: 18px;
    font-weight: 600;

    &:first-child {
      margin-top: 120px;
    }
  }
}
