.fieldsWrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  margin-bottom: 20px;
}

.searchField {
  width: 300px;
}

.filterField {
  min-width: 220px;
}
