@import 'styles/variables';
@import 'styles/colors';

.hamburger {
  position: relative;
  height: 50px;
  width: 50px;
  transition: width 0.3s ease-in-out;
  z-index: 21;
}

.bars {
  --color: #353535;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  outline: none;
  position: absolute;
  border: none;
  background: none;
  cursor: pointer;
  -webkit-appearence: none;
  -webkit-tap-highlight-color: transparent;

  svg {
    width: 55px;
    height: 48px;
    top: -6px;
    left: -6px;
    stroke: var(--color);
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    display: block;
    position: absolute;
    path {
      transition: stroke-dasharray var(--duration, 0.85s) var(--easing, ease) var(--delay, 0s),
        stroke-dashoffset var(--duration, 0.85s) var(--easing, ease) var(--delay, 0s);
      stroke-dasharray: var(--array-1, 26px) var(--array-2, 100px);
      stroke-dashoffset: var(--offset, 126px);
      transform: translateZ(0);
      &:nth-child(2) {
        --duration: 0.7s;
        --easing: ease-in;
        --offset: 100px;
        --array-2: 74px;
      }
      &:nth-child(3) {
        --offset: 133px;
        --array-2: 107px;
      }
    }
  }
}

.active {
  svg {
    path {
      --offset: 57px;
      &:nth-child(1),
      &:nth-child(3) {
        --delay: 0.15s;
        --easing: cubic-bezier(0.2, 0.4, 0.2, 1.1);
      }
      &:nth-child(2) {
        --duration: 0.4s;
        --offset: 2px;
        --array-1: 1px;
      }
      &:nth-child(3) {
        --offset: 58px;
      }
    }
  }
}
