@import 'styles/colors';
@import 'styles/breakpoints';

.container {
  background-color: $color-white;
  width: 100%;
  padding: 1.25rem;
  margin-top: 1rem;

  @include sm-down {
    padding: 1.25rem 0;
  }
}
