$spacer: 1rem;
$spacer-xs: $spacer * 0.25;
$spacer-sm: $spacer * 0.5;
$spacer-base: $spacer;
$spacer-lg: $spacer * 1.5;
$spacer-xl: $spacer * 2;
$spacer-2xl: $spacer * 3;
$spacer-3xl: $spacer * 4;

$border-radius: 0.5rem;
$border-radius-xs: $border-radius * 0.25;
$border-radius-sm: $border-radius * 0.5;
$border-radius-base: $border-radius;
$border-radius-lg: $border-radius * 1.5;
$border-radius-xl: $border-radius * 2;

$box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
$box-shadow-xs: 0 0 0 1px rgba(255, 255, 255, 0.05);
$box-shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
$box-shadow-md: $box-shadow;
$box-shadow-lg: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
$box-shadow-xl: 0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 10px 15px -3px rgba(0, 0, 0, 0.1);
$box-shadow-2xl: 0 10px 10px -5px rgba(0, 0, 0, 0.04), 0 20px 25px -5px rgba(0, 0, 0, 0.1);
$box-shadow-3xl: 0 25px 50px -12px rgba(0, 0, 0, 0.25);

$font-size: 1rem;
$font-size-2xs: 0.625rem;
$font-size-xs: 0.75rem;
$font-size-sm: 0.875rem;
$font-size-base: $font-size;
$font-size-lg: 1.125rem;
$font-size-xl: 1.25rem;
$font-size-2xl: 1.5rem;
$font-size-3xl: 1.875rem;
$font-size-4xl: 2.25rem;
$font-size-5xl: 3rem;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;

$line-height-sm: 1.15;
$line-height-base: 1.3;
$line-height-lg: 1.5;
$line-height-xl: 1.8;

$letter-spacing-sm: -0.025em;

$duration: 275ms;
$duration-sm: 175ms;
$duration-base: $duration;
$duration-lg: 375ms;
$duration-xl: 500ms;

// $font-family-basic: 'Montserrat', 'Arial', sans-serif;
$font-family-basic: 'Poppins', -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui,
  helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;

$form-element-height-xs: 2.5rem;
$form-element-height-md: 3rem;

$container-padding-sm: $spacer-sm;
$container-padding-md: $spacer-xl;
