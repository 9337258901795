@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/variables';

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../../public/fonts/poppins-v20-latin-ext-regular.woff2') format('woff2'),
    url('../../public/fonts/poppins-v20-latin-ext-regular.woff') format('woff');
  font-display: swap;
}
/* poppins-500 - latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('../../public/fonts/poppins-v20-latin-ext-500.woff2') format('woff2'),
    url('../../public/fonts/poppins-v20-latin-ext-500.woff') format('woff');
  font-display: swap;
}
/* poppins-600 - latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local(''), url('../../public/fonts/poppins-v20-latin-ext-600.woff2') format('woff2'),
    url('../../public/fonts/poppins-v20-latin-ext-600.woff') format('woff');
  font-display: swap;
}
/* poppins-700 - latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('../../public/fonts/poppins-v20-latin-ext-700.woff2') format('woff2'),
    url('../../public/fonts/poppins-v20-latin-ext-700.woff') format('woff');
  font-display: swap;
}

html {
  color: $color-gray;
  font-family: $font-family-basic;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-numeric: tabular-nums;
  font-weight: $font-weight-regular;
}

body {
  margin: 0;
  min-height: 100vh;
  overflow-y: scroll;
}

// ::selection {
//   background-color: $color-primary;
//   color: $color-white;
// }

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  transition: color $duration-base;
}

[role='button'] {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // font-style: italic;
  margin: 0;
}

p {
  color: $color-gray;
  font-size: $font-size-sm;
  line-height: $line-height-xl;
  margin: 0;
}

.container {
  padding-left: $container-padding-sm;
  padding-right: $container-padding-sm;

  @include md-up {
    padding-left: $container-padding-md;
    padding-right: $container-padding-sm;
  }
}

.tooltip > .tooltip-inner {
  background-color: $color-primary;
}
.tooltip {
  font-weight: 300 !important;
  // letter-spacing: 1px;
  opacity: 1 !important;
  font-family: $font-family-basic;
  font-weight: $font-weight-regular !important;
  background-color: $color-primary !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
}
.tooltip.place-top {
  &:after {
    border-top-color: $color-primary !important;
    border-top-width: 8px !important;
  }
}
.tooltip.place-right {
  &:after {
    border-right-color: $color-primary !important;
    border-right-width: 6px !important;
  }
}
