@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/variables';

.modal {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.26);

  &__header {
    padding-bottom: $spacer-base;
    border-bottom: 1px solid rgba(243, 243, 243, 1);
  }

  &__title {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    color: rgba(91, 91, 91, 1);
  }

  &__content {
    padding-top: $spacer-lg;

    form {
      display: grid;
      flex-direction: column;
      row-gap: 1.5rem;
    }
  }

  &__inner {
    overflow: auto;
    padding: $spacer-base;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 100%;
    height: 100%;

    @include md-up {
      max-height: 100%;
      border-radius: $border-radius-base;
      box-shadow: 0 $spacer-xs 2.5rem #00000029;
      padding: 2.3rem 1.3rem;
      min-width: 23rem;
      width: unset;
      height: unset;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    .btn--transparent {
      color: rgba(180, 180, 180, 1);
    }
  }

  &__group-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: $spacer-base;
  }

  &__group-label {
    color: rgba(99, 99, 99, 1);
    font-size: $font-size-base;
    padding-bottom: $font-size-sm;
  }

  &__group-container {
    display: flex;
    justify-content: flex-start;
    .modal__field-wrapper {
      &:first-of-type {
        width: 50%;
      }
    }
  }

  &__field-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: $spacer-xs;
    padding-bottom: 1.25rem;
  }

  &__field-label {
    font-size: $font-size-sm;
    color: rgba(0, 0, 0, 0.41);
    padding-bottom: $spacer-sm;
  }

  &__input {
    font-size: $font-size-sm;
    color: rgba(17, 17, 17, 0.97);
  }

  &__possible-duplicates {
    h4 {
      font-weight: $font-weight-regular;
      color: $color-neutral-500;
      padding-top: $spacer-base;
    }

    .table {
      padding: $spacer-sm 0 0 0;
    }

    .checkbox {
      justify-content: center;
      margin-top: $spacer-lg;
    }
  }

  &__alert {
    font-size: $font-size-sm;
    color: $color-red;
  }
}
