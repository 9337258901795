@import 'styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 1.5rem;

  &__title {
    padding-bottom: $spacer-lg;
    align-self: center;
  }
}
