.wrapper {
  // display: flex;
  align-items: center;
  // align-items: center;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;

  height: 64px;
  border-bottom: 1px solid #e8ebf0;
  padding: 16px 24px 24px;
}

h2 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  justify-self: center;
}

.reset {
  font-size: 12px;
  font-weight: 600;
  color: #8d5b4c;
}

.exit {
  display: inline-flex;
  justify-self: flex-end;
}

.disabled {
  color: rgb(176, 176, 176);
}
