@import 'styles/colors';

.audio-status {
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-orange;

  &--DUPLICATE {
    background-color: #c5c3f6;
  }
  &--ERROR {
    background-color: #fbc2c2;
  }
}
