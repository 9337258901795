@import 'styles/colors';
@import 'styles/variables';

.link {
  color: $color-primary;
  font-size: $font-size-base;
  font-weight: $font-weight-regular;
  text-decoration: underline;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
