@import 'styles/colors';
@import 'styles/variables';
@import 'styles/breakpoints';

.header {
  background-color: $color-primary;
  color: $color-white;
  height: 8rem;
  padding: 0 6.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-image: url(../../../assets/files/merged-waves.svg);
  background-position: center;
  background-size: 100%;

  @include sm-down() {
    padding: 0 20px;
  }

  &__link {
    display: flex;
    align-items: center;
    font-size: $font-size-sm;
    text-decoration: none;
    color: inherit;
    cursor: pointer;

    .svg {
      width: 0.7rem;
      transform: rotate(90deg);
      margin-right: 0.7rem;
    }
  }

  &__title {
    font-size: $font-size-3xl;
    font-weight: $font-weight-bold;
  }
}
