@import 'styles/colors';

.preloader {
  position: fixed;
  //   backdrop-filter: blur(2px);
  //   background-color: rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  width: 100%;
  height: 100%;
  pointer-events: none;
  svg {
    animation: rotate 1.5s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 60px;
    height: 60px;

    & .path1 {
      stroke: $color-primary;
      stroke-linecap: round;
      animation: dash 1.2s ease-in-out infinite;
    }
    & .path2 {
      stroke: $color-purple;
      stroke-linecap: round;
      animation: dash2 1.2s ease-in-out infinite;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

  @keyframes dash2 {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -62;
    }
  }
}
