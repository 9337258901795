@import 'styles/colors';

.play {
  width: 1.875rem;
  height: 1.875rem;
  background-color: rgba($color-blue, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;

  .svg {
    margin-left: 9px;
  }
}
