@import 'styles/colors';
@import 'styles/variables';

.regular-field {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;

  &__label {
    font-size: 14px;
    color: #353535;
    font-weight: $font-weight-semi-bold;
  }

  &__input {
    height: 48px;
    width: 100%;
    border: 1px solid #ebebeb;
    border-radius: 12px;
    padding: 0 16px;
    color: rgba(53, 53, 53, 1);
    font-size: 16px;
    font-family: $font-family-basic;
    font-weight: $font-weight-semi-bold;
    outline: none;

    &--error {
      border-color: $color-red;
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.6;
    }

    &:hover {
      border-color: $color-neutral-600;
      cursor: pointer;
    }

    &:focus {
      border-color: #353535;
    }

    &::placeholder {
      color: rgba(53, 53, 53, 0.6);
      font-weight: $font-weight-regular;
    }
  }

  &__clear {
    position: absolute;
    display: flex;
    align-items: center;
    height: 48px;
    width: 16px;
    right: 14px;
    cursor: pointer;

    svg {
      width: 13px;
    }

    &--aligned {
      top: 29px;
    }
  }
}
