$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 1230px;
$screen-xl-min: 1400px;

@mixin xs-only {
  @media screen and (max-width: #{$screen-sm-min - 1}) {
    @content;
  }
}

@mixin sm-only {
  @media screen and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-md-min - 1}) {
    @content;
  }
}

@mixin md-only {
  @media screen and (min-width: #{$screen-md-min}) and (max-width: #{$screen-lg-min - 1}) {
    @content;
  }
}

@mixin lg-only {
  @media screen and (min-width: #{$screen-lg-min}) and (max-width: #{$screen-xl-min - 1}) {
    @content;
  }
}

@mixin xl-only {
  @media screen and (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin xs-down {
  @media screen and (max-width: #{$screen-sm-min - 1}) {
    @content;
  }
}

@mixin sm-down {
  @media screen and (max-width: #{$screen-md-min - 1}) {
    @content;
  }
}

@mixin md-down {
  @media screen and (max-width: #{$screen-lg-min - 1}) {
    @content;
  }
}

@mixin lg-down {
  @media screen and (max-width: #{$screen-xl-min - 1}) {
    @content;
  }
}

@mixin sm-up {
  @media screen and (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md-up {
  @media screen and (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg-up {
  @media screen and (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xl-up {
  @media screen and (min-width: #{$screen-xl-min}) {
    @content;
  }
}
