.wrapper {
  position: fixed;
  width: 100%;
  bottom: 0;
  border-top: 1px solid #e8ebf0;
  height: 72px;
  display: flex;
  align-items: center;
  padding: 0px 14px;
  background-color: #fff;
}
