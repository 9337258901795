@import 'styles/colors';
@import 'styles/variables';

.select-field {
  &__label {
    font-size: 14px;
    color: #353535;
    font-weight: $font-weight-semi-bold;
    margin-bottom: 8px;
  }
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
  }
  &__option {
    display: flex;
    align-items: center;
    font-size: $font-size-sm;
    column-gap: 5px;
  }

  .react-select {
    &__control {
      background-color: #fff;
      border: 1px solid $color-neutral-200;
      border-radius: 12px;
      height: 48px;

      &:focus {
        border: 1px solid #353535;
        box-shadow: none;
      }
      &:hover {
        border-color: $color-neutral-600;
        cursor: pointer;
        box-shadow: none;
      }

      &--is-focused {
        border: 1px solid #353535;
        box-shadow: none;
        &:hover {
          border: 1px solid #353535;
          box-shadow: none;
        }
      }
    }
    &__indicator-separator {
      display: none;
    }
    &__indicator {
      align-items: center;
      padding: 0.25rem 16px 0.25rem 0;
      cursor: pointer;

      .svg {
        width: 15px;

        &--exit {
          width: 13px;
        }
      }
    }
    &__indicators {
      margin-left: 7px;
      height: 48px;
    }
    &__menu,
    &__single-value,
    &__placeholder,
    &__input {
      font-size: 15px;
      font-family: $font-family-basic;
      font-weight: $font-weight-regular;
      color: #353535;
    }
    &__single-value {
      font-weight: $font-weight-semi-bold;
    }
    &__placeholder {
      margin-left: 0;
      color: rgba(53, 53, 53, 0.6);
    }
    &__value-container {
      padding-left: 16px;
      // &--has-value {
      //   ~ .react-select__dropdown-indicator {
      //     display: none;
      //   }
      // }
    }
    &__menu {
      z-index: 100;
      border-radius: 12px;
      border: none;
      background-color: #fff;
      box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
    }
    &__menu-list {
      // border-radius: 12px;
      background-color: transparent;
    }
    &__option {
      min-height: 48px;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      color: #353535;
      font-size: $font-weight-regular;

      &--is-focused {
        background-color: transparent;
        font-weight: 500;
      }

      &--is-selected {
        background-color: transparent;
        font-weight: 600;
      }
      &:active {
        background-color: #fff;
      }
      &:hover {
        cursor: pointer;
        font-weight: 500;
      }
    }
  }

  &__input {
    &--error {
      .react-select__control {
        border: 1px solid $color-red;
      }
    }

    &--with-custom-label {
      .react-select__value-container {
        padding-left: 0.375rem;
      }

      .react-select__placeholder {
        padding-left: 0.625rem;
      }

      .status {
        width: $spacer-lg;
        height: $spacer-lg;
        margin-right: 0.5rem;

        .svg {
          width: 0.685rem;
        }
      }
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.6;
    }
  }
}
