@import 'styles/colors.scss';
@import 'styles/variables.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 45px 0;
  column-gap: 24px;
}

.itemsWrapper {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.active {
  background-color: #eee7e4;
  color: #8d5b4c;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  user-select: none;
  font-weight: $font-weight-medium;

  &:hover {
    background-color: #eee7e4;
    color: #8d5b4c;
    cursor: pointer;
  }
}

.chevron {
  svg {
    width: 16px;
    height: 16px;

    path {
      fill: $color-black;
    }
  }

  &:first-child {
    svg {
      transform: rotate(180deg);
    }
  }
}

.disabled {
  svg {
    path {
      fill: rgba($color-black, 0.2);
    }
  }

  &:hover {
    background-color: transparent;
    cursor: default;
  }
}
