@import 'styles/colors';
@import 'styles/variables';

.navigation {
  display: flex;
  flex-direction: column;

  &__item {
    height: 3.75rem;
    width: 100%;
    display: flex;
    align-items: center;
    color: $color-white;
    overflow: hidden;
    font-size: 0.93rem;

    &:hover,
    &.active {
      background-color: #3c3c51;

      .svg {
        .a {
          fill: $color-purple;
        }
      }

      &:before {
        content: '';
        height: 3.75rem;
        width: $spacer-xs;
        left: 0;
        position: absolute;
        background-color: $color-purple;
      }
    }
  }

  &__icon {
    width: 4.125rem;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;

    .svg {
      width: 1.875rem;
    }
  }
}
