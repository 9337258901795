.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.animation {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-size: 700px !important;
  animation-name: wave;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background-repeat: no-repeat;
  //   animation-fill-mode: forwards;
  background: linear-gradient(to right, rgba(#fff, 0) 20%, rgba(#fff, 0.4) 50%, rgba(#fff, 0) 80%);
}

.item {
  height: inherit;
  //   background-color: #efeff0;
  // background-color: #f0f0f0;
  background-color: #f5f5f5;
}

.default {
  border-radius: 8px;
}

.small {
  border-radius: 4px;
}

.circle {
  border-radius: 50%;
}

@keyframes wave {
  0% {
    background-position: -700px 0;
  }

  50% {
    background-position: 0 0;
  }

  100% {
    background-position: 700px 0;
  }
}
