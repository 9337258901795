@import 'styles/colors';
@import 'styles/variables';
@import 'styles/breakpoints';

.total-amount {
  font-size: 0.875rem;
  padding-top: 12px;

  @include sm-down {
    margin-right: 10px;
  }
}
