@import 'styles/colors';

.status {
  height: 1.875rem;
  width: 1.875rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &--success {
    background-color: $color-green;
  }

  &--pending {
    background-color: $color-orange;
  }
}
