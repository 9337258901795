@import 'styles/breakpoints';
@import 'styles/variables';
@import 'styles/colors';

.wrapper {
  height: 64px;
  background-color: $color-white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;

  @include lg-up {
    padding: 0 150px;
  }
}

.logo {
  display: flex;
  align-items: center;
  column-gap: 14px;

  h1 {
    font-size: 16px;
    color: $color-black;
    font-weight: $font-weight-semi-bold;
  }
}
