@import 'styles/variables';
@import 'styles/colors';

.wrapper {
  display: flex;
  align-items: center;
  column-gap: 45px;

  a {
    text-decoration: none;
    font-size: 14px;
    color: $color-black;
    font-weight: $font-weight-medium;

    &:hover {
      color: rgb(101, 101, 101);
    }
  }
}
