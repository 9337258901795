.svg {
  &--chevron-down {
    .a {
      fill: none;
      stroke: #c3c3c3;
      stroke-width: 4px;
    }
  }

  &--home,
  &--users,
  &--preacher,
  &--location,
  &--recording {
    .a {
      fill: #a5a4bf;
    }
  }
  &--dots {
    .a {
      fill: #a3a6b4;
    }
  }
  &--status-pending {
    .a {
      fill: #ffda83;
    }
  }
  &--status-success {
    .a {
      fill: #5fe2a0;
    }
  }
  &--plus {
    .a {
      fill: #a5a4bf;
    }
  }
  &--play {
    .a {
      fill: #3b86ff;
    }
  }
}
