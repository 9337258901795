@import 'styles/variables';
@import 'styles/colors';

.progress-bar {
  position: fixed;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10rem;
  flex-direction: column;
  z-index: 999;
  width: 100%;
  height: 100%;
  pointer-events: none;
  text-align: center;

  &__title {
    font-size: $font-size-2xl;
    color: $color-black;
  }

  &__description {
    font-size: $font-size-xs;
    color: #acabab;
    padding: $spacer-base 0 $spacer-3xl;
    font-weight: $font-weight-light;
    line-height: 1rem;
  }

  circle {
    // transition: all 0.4s ease-in-out;
    transition: stroke-dashoffset 0.2s ease-in;
  }

  .time {
    fill: $color-neutral-500;
  }
}
