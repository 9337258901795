@import 'styles/variables';
@import 'styles/colors';

.checkbox {
  &__label {
    display: flex;
    align-items: center;
    font-size: $font-size-sm;
    color: $color-neutral-500;
    cursor: pointer;
  }

  input[type='checkbox'] {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: $spacer-sm;
    cursor: pointer;
  }
}
