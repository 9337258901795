@import 'styles/variables';

.wrapper {
  display: inline-flex;
  align-items: center;
  border-radius: 20px;
  padding: 0 16px;
  height: 40px;
  font-size: 14px;
  font-weight: $font-weight-medium;
  color: #fff;
  line-height: 0;
  cursor: pointer;
}

.light {
  background-color: rgba(165, 151, 139, 0.15);
  color: #867b71;
}

.gray {
  background-color: rgba(92, 71, 66, 0.15);
  color: #5c4742;
}

.peach {
  background-color: rgba(141, 91, 76, 0.15);
  color: #8d5b4c;
}
