// Default
$color-black: #353535;
$color-light-gray: #43425d80;
$color-gray: #4d4f5c;
$color-white: #ffffff;
$color-blue: #3b86ff;
$color-purple: #a3a0fb;
$color-red: #ff634e;
$color-orange: #fff4e5;
$color-green: #daf7e8;
$color-transparent: rgba($color-white, 0);

// Brand
$color-primary: #43425d;

// Neutrals
$color-neutral-100: #fafafa;
// $color-neutral-100: #808495;
$color-neutral-200: #e5e5e5;
$color-neutral-300: #d6d6d6;
$color-neutral-500: #9a9a9a;
$color-neutral-600: #707070;
$color-neutral-800: #4a4a4a;
$color-neutral-900: #1a1a1a;
