@import 'styles/colors';
@import 'styles/variables';

.table {
  // background-color: $color-white;
  width: 100%;
  overflow: scroll;
  // padding: 1.25rem;

  table {
    width: 100%;
    border-spacing: 0 0.4rem;
  }

  thead {
    height: 2.8125rem;
    background-color: #f5f6fa;
  }

  tbody {
    tr {
      height: 3.125rem;
      font-size: 0.75rem;
    }
  }
  .placeholderRow {
    th,
    td {
      padding-left: 0 !important;
    }
  }

  th {
    font-weight: $font-weight-bold;
    color: #a3a6b4;
    text-transform: uppercase;
    font-size: 0.75rem;
    padding: 0 1.25rem;
    height: 2.8125rem;
  }

  td {
    position: relative;
    padding: 0 0.375rem;
    white-space: nowrap;
    border-bottom: 1px solid #f1f1f3;
    // font-size: 0.8rem;
    font-size: 12px;
    color: rgba($color-black, 0.9);
    font-weight: $font-weight-light;

    // TODO add resizing to columns
    max-width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;

    &.actions {
      overflow: unset;
    }

    .dots {
      padding: 0 2rem;
      display: flex;
      justify-content: flex-end;
      &__icon {
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 3.125rem;
        width: 1.25rem;

        &--modal {
          position: absolute;
          right: $spacer-base;
          display: flex;
          align-items: center;
          height: 2.125rem;
          cursor: pointer;
        }

        .svg {
          &--exclamation-mark {
            position: absolute;
            right: 1.5rem;
          }
        }
      }

      &__modal {
        position: absolute;
        width: 10rem;
        background: white;
        right: 1.375rem;
        top: 0.5rem;
        border-radius: $border-radius-base;
        box-shadow: 0px 2px 10px #e0e0e0;
        z-index: 1;
      }

      &__item-wrapper {
        padding: $spacer-lg $spacer-base $spacer-base;
      }

      &__item {
        font-size: $font-size-sm;
        font-weight: $font-weight-regular;
        cursor: pointer;
        color: #a3a6b4;

        &:not(:last-child) {
          margin: 0 0 $spacer-base 0;
        }

        &.red {
          color: $color-red;
        }
      }
    }
  }

  .status {
    margin: 0 auto;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacer-lg 0 $spacer-sm;

  button {
    border: 1px solid #a3a6b4;
    outline: none;
    border-radius: 4px;
    min-width: 25px;
    background: white;
    display: inline-block;
    height: 25px;
    margin: 0 5px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
  }

  &__location {
    font-size: 15px;
    padding: 0 20px;

    span {
      font-weight: $font-weight-bold;
      &:first-of-type {
        margin-left: 5px;
      }
    }
  }

  select {
    background-color: #fff;
    border: 1px solid $color-neutral-200;
    border-radius: $border-radius-sm;
    height: 2rem;
    font-family: $font-family-basic;
    padding: 0 5px;
    cursor: pointer;
  }
}

.table {
  &__all-sessions {
    overflow: unset;

    thead {
      background-color: transparent;
      vertical-align: bottom;
    }
    th {
      font-weight: $font-weight-semi-bold;
      color: #000;
      font-size: 12px;
      text-transform: initial;
      text-align: left;
      padding: 0;
      // height: 2.8125rem;
      &:first-child {
        padding-left: 20px;
      }
      &:last-child {
        padding-left: 20px;
      }
    }
    tbody {
      tr {
        padding-left: 20px;
        box-shadow: 0px 0px 40px rgba(177, 177, 177, 0.1);
        height: 58px;
        border-radius: 8px;

        &:hover {
          cursor: pointer;
          // background-color: #f2f2f2;
          background-color: #f7f7f7;
        }
      }
    }
    td {
      padding: 0;
      border-bottom: none;

      &:first-child {
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 8px 0 0 8px;
      }
      &:last-child {
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 0 8px 8px 0;
      }
    }
    .placeholderRow {
      td {
        &:first-child {
          padding-right: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}
