@import 'styles/colors';
@import 'styles/variables';

.sidebar {
  position: fixed;
  width: 4.125rem;
  height: 100vh;
  background-color: $color-primary;
  transition: width 0.2s ease-in-out;
  z-index: 2;

  &--expanded {
    width: 16.25rem;
    transition: width 0.2s ease-in-out;
    .hamburger {
      width: 100%;

      &__bars {
        position: relative;
        padding-right: $spacer-3xl;
        float: left;
      }

      &__logo {
        line-height: 4.375rem;
        white-space: nowrap;
      }
    }

    .navigation {
      &__item {
        justify-content: flex-start;
        white-space: nowrap;
      }

      &__item-wrapper {
        white-space: nowrap;
      }
    }
  }
}
